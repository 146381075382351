import axios from 'axios'
import { showNotify } from 'vant'
import 'vant/es/notify/style'
import { getUserInfo, setErrorInfo } from '@/utils/storage'
import router from '../router'

const instance = axios.create({
  timeout: 60 * 1000,
  headers: {
    'Content-Type': 'application/json;charset=UTF-8'
  },
  baseURL: process.env.VUE_APP_API_BASE_URL
})
instance.interceptors.request.use((req) => {
  return req
})
instance.interceptors.response.use(
  (response) => {
    const { data } = response
    if (data) {
      if (data.code === 0) {
        return Promise.resolve(data)
      } else {
        if (data.code === 403) {
          if (!router.currentRoute.value.name !== 'noPermission') {
            setErrorInfo(data.error)
            router.replace('/noPermission')
          }
        }
        return Promise.reject(data)
      }
    }
    return Promise.reject(response)
  },
  (error) => {
    return Promise.reject(error)
  }
)

export default function request(config, options) {
  let method = config.method || 'get'
  const userinfo = getUserInfo()
  if (method === 'get') {
    config.params = config.params || {}
    if (userinfo) {
      config.params.uid = userinfo.uid
    }
  } else {
    config.data = config.data || {}
    if (userinfo) {
      config.data.uid = userinfo.uid
    }
  }
  options = options || {}
  if (options.showErrorMsg !== false) {
    options.showErrorMsg = true
  }
  options.showSuccessMsg = options.showSuccessMsg || false
  return instance(config)
    .then((res) => {
      if (options.showSuccessMsg === true) {
        showNotify({
          message: options.successMsg || res.msg,
          type: 'success'
        })
      }
      return Promise.resolve(res)
    })
    .catch((error) => {
      if (options.showErrorMsg !== false) {
        showNotify({ message: options.errorMsg || error.msg || error })
      }
      return Promise.reject(error)
    })
}
