import engine from 'store/src/store-engine'
import sstorage from 'store/storages/sessionStorage'
import lstorage from 'store/storages/localStorage'

export const ls = engine.createStore([lstorage])
export const ss = engine.createStore([sstorage])

const storageMap = {
  ls,
  ss
}

export const TOKEN = 'token'
export const USERINFO = 'userinfo'
export const NEXTINFO = 'next_info'
export const SMSCODE_TIME = 'smscode_time'
export const ERROR_INFO = 'error_info'

export function getToken(type = 'ls') {
  return storageMap[type].get(TOKEN)
}

export function setToken(value, type = 'ls') {
  storageMap[type].set(TOKEN, value)
}

export function getUserInfo(type = 'ls') {
  return storageMap[type].get(USERINFO)
}

export function setUserInfo(value, type = 'ls') {
  storageMap[type].set(USERINFO, value)
}

export function clearUserInfo (type = 'ls') {
  storageMap[type].remove(USERINFO)
}

export function getNextInfo(type = 'ls') {
  return storageMap[type].get(NEXTINFO)
}

export function setNextInfo(value, type = 'ls') {
  storageMap[type].set(NEXTINFO, value)
}
export function setSmsCodeTimeInfo(value, type = 'ls') {
  storageMap[type].set(SMSCODE_TIME, value)
}

export function getSmsCodeTimeInfo(type = 'ls') {
  return storageMap[type].get(SMSCODE_TIME)
}
export function setErrorInfo(value, type = 'ls') {
  storageMap[type].set(ERROR_INFO, value)
}

export function getErrorInfo(type = 'ls') {
  return storageMap[type].get(ERROR_INFO)
}