export default [
  {
    path: '/',
    name: 'index',
    component: () => import('@/views/index')
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('@/views/home')
  },
  {
    path: '/noPermission',
    name: 'noPermission',
    component: () => import('@/views/noPermission')
  },
  {
    path: '/bind-wait',
    name: 'bind-wait',
    component: () => import('@/views/bind-wait')
  },
  {
    path: '/waiting',
    name: 'waiting',
    component: () => import('@/views/waiting')
  },
  {
    path: '/edit',
    name: 'edit',
    component: () => import('@/views/edit')
  },
]
