import { createApp } from 'vue'
import App from './App.vue'
import './assets/styles/tailwindcss-output.css'
import router from './router'
import './permission'
// import VConsole from 'vconsole';

// new VConsole();

const app = createApp(App)
app.use(router)

app.mount('#app')
