import { onMounted, onUnmounted } from 'vue'

export default function schedule(cb, time = 2, immediate = false) {
  let timer = null

  onMounted(() => {
    start()
  })
  onUnmounted(() => {
    clear()
  })

  function clear() {
    clearInterval(timer)
    timer = null
  }
  function start() {
    if (immediate) {
      cb()
    }
    timer = setInterval(() => {
      cb()
    }, time * 1000)
  }
  return () => {
    clear()
  }
}
